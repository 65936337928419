import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  TextareaAutosize,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { maleData, femaleData } from './bone_age_data';

function CalculadoraIdadeOssea() {
  const [sex, setSex] = useState('M');
  const [anosCronologicos, setAnosCronologicos] = useState('0');
  const [mesesCronologicos, setMesesCronologicos] = useState('0');
  const [anosObservados, setAnosObservados] = useState('0');
  const [mesesObservados, setMesesObservados] = useState('0');
  const [report, setReport] = useState('');

  const calcular = () => {
    const data = sex === 'M' ? maleData : femaleData;
    const idadeCronologicaEmMeses =
      parseInt(anosCronologicos, 10) * 12 + parseInt(mesesCronologicos, 10);
    const idadeOsseaObservadaEmMeses =
      parseInt(anosObservados, 10) * 12 + parseInt(mesesObservados, 10);

    if (isNaN(idadeCronologicaEmMeses) || isNaN(idadeOsseaObservadaEmMeses)) {
      alert('Por favor, insira idades válidas.');
      return;
    }

    const closestData = data.reduce((prev, curr) => {
      return Math.abs(curr.age - idadeCronologicaEmMeses) <
        Math.abs(prev.age - idadeCronologicaEmMeses)
        ? curr
        : prev;
    }, data[0]);

    const deviation =
      (idadeOsseaObservadaEmMeses - closestData.meanSkeletalAge) /
      closestData.stdDev;

    let conclusao = '';
    if (deviation > 2) {
      conclusao =
        'A idade óssea observada encontra-se acima de dois desvios-padrões em relação à idade cronológica.';
    } else if (deviation < -2) {
      conclusao =
        'A idade óssea observada encontra-se abaixo de dois desvios-padrões em relação à idade cronológica.';
    } else {
      conclusao =
        'A idade óssea observada encontra-se dentro dos limites da normalidade.';
    }

    setReport(
      `Sexo: ${sex === 'M' ? 'masculino' : 'feminino'}.
Idade cronológica: ${anosCronologicos} anos e ${mesesCronologicos} meses (${idadeCronologicaEmMeses} meses).
Idade óssea observada: ${anosObservados} anos e ${mesesObservados} meses (${idadeOsseaObservadaEmMeses} meses).
Desvio-padrão (Fundação Brush): ${closestData.stdDev.toFixed(2)} meses.

CONCLUSÃO:
${conclusao}
`
    );
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(report);
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Calculadora de Idade Óssea
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="sex-select-label">Sexo</InputLabel>
            <Select
              labelId="sex-select-label"
              id="sex-select"
              value={sex}
              label="Sexo"
              onChange={(e) => setSex(e.target.value)}
            >
              <MenuItem value="M">Masculino</MenuItem>
              <MenuItem value="F">Feminino</MenuItem>
            </Select>
          </FormControl>

          <Typography variant="subtitle1" gutterBottom>
            Idade cronológica
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Anos"
                type="number"
                value={anosCronologicos}
                onChange={(e) => setAnosCronologicos(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Meses"
                type="number"
                value={mesesCronologicos}
                onChange={(e) => setMesesCronologicos(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>

          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            Idade óssea observada
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Anos"
                type="number"
                value={anosObservados}
                onChange={(e) => setAnosObservados(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Meses"
                type="number"
                value={mesesObservados}
                onChange={(e) => setMesesObservados(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            onClick={calcular}
            sx={{ mt: 2 }}
          >
            Gerar Relatório
          </Button>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6">Relatório:</Typography>
          <TextareaAutosize
            minRows={10}
            value={report}
            readOnly
            style={{
              width: '100%',
              marginTop: '8px',
              padding: '8px',
              fontSize: '16px',
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            startIcon={<FileCopyIcon />}
            onClick={copyToClipboard}
            sx={{ mt: 1 }}
          >
            Copiar para o Clipboard
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" sx={{ fontStyle: 'italic', mt: 2 }}>
            Gaskin, C. M., Kahn, S. L., Bertozzi, J. C., & Bunch, P. M. (2011).
            Skeletal Development of the Hand and Wrist: A Radiographic Atlas and
            Digital Bone Age Companion. Oxford University Press.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default CalculadoraIdadeOssea;
