import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Inicio from './Main/Inicio';
import Ferramentas from './Main/Ferramentas';
import Laudos from './Main/Laudos';
import Tirads from './Main/Ferramentas/Tirads/Tirads';
import ORadsRM from './Main/Ferramentas/ORads/RM/ORadsRM';
import Construcao from './Main/Construcao';
import RmFerro from './Main/Ferramentas/RmFerro/RmFerro';
import AgeBoneCalculator from './Main/Ferramentas/IdadeOssea/IdadeOssea';
import LiverFatCalculator from './Main/Ferramentas/LiverFatCalculatorCT/LiverFatCalculatorCT';
import Mascaras from './Mascaras/Mascaras';
import TermsOfService from './TermsOfService';
import BiradsUltrassom from './Main/Ferramentas/Mama/BiradsUltrassom';
import SpeechToText from './Main/Ferramentas/SpeechToText/SpeechToText';
import RenalScoreCalculator from './Main/Ferramentas/RenalScoreCalculator/RenalScoreCalculator';

function Main() {
  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="/ferramentas" element={<Ferramentas />} />
      <Route path="/ferramentas/tirads" element={<Tirads />} />
      <Route path="/ferramentas/oradsrm" element={<ORadsRM />} />
      <Route path="/ferramentas/rmferro" element={<RmFerro />} />
      <Route path="/ferramentas/idadeossea" element={<AgeBoneCalculator />} />
      <Route path="/ferramentas/birads-ultrassom" element={<BiradsUltrassom />} />
      <Route path="/laudos" element={<Laudos />} />
      <Route path="/mascaras" element={<Mascaras />} />
      <Route path="/ferramentas/fracaogorduract" element={<LiverFatCalculator />} />
      <Route path="/ferramentas/speech2text" element={<SpeechToText />} />
      <Route path="/ferramentas/renalscorecalculator" element={<RenalScoreCalculator />} />
      <Route path="/emconstrucao" element={<Construcao />} />
      <Route path="/terms" element={<TermsOfService />} />
    </Routes>
  );
}

export default Main;